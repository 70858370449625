@import url("https://fonts.googleapis.com/css?family=Overpass:200,400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;1,600&display=swap");
html,
body,
#root {
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #f9f4e3;
  /* font-size: 12px; */
}

.screen-wrap {
  width: 100%;
  height: 100%;
  background-color: #f4b640;
}

#home-page {
  background-color: #f9f4e3;
}

#waiting-room,
#game-lobby-wrapper {
  background-color: #f4b640;
}

.page {
  position: relative;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s;
  padding: 0;
}

@keyframes slide-in {
  0% {
    top: 0;
    left: 100vw;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 720px) {
  @keyframes slide-in {
    0% {
      top: 0;
      left: 100vw;
    }
    100% {
      top: 0;
      left: 0;
    }
  }
}

.fade-enter {
  animation: fade-out 3s forwards;
  position: absolute;
  z-index: 0;
}

.slide-enter {
  position: absolute;
  transition: 1s;
  animation: slide-in 2s forwards;
  z-index: 10;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.banner {
  /* position: absolute;
    left: 0;
    top: 0; */
  /* padding: 0.5rem; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  font-family: "Overpass", sans-serif;
  font-weight: 900;
  font-size: 120%;
  letter-spacing: 2.92px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.icon {
  width: 10%;
  margin-right: 1.5rem;
  /* flex-shrink: 0; */
}

#back-icon {
  width: 10px;
  transform: rotate(180deg);
  padding: 1rem;
  margin-left: 0.5rem;
  max-width: 50px;
  cursor: pointer;
}

nav {
  background-color: #152c51;
  /* position: sticky; */
  position: fixed;
  margin-top: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  text-align: center;
}

nav a {
  font-family: "Open Sans", "Overpass", sans-serif;
  font-size: 14px;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 500;
  color: #f9f4e3;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.navLink-active {
  color: #e45c33;
}

.h1 {
  font-family: "Overpass", sans-serif;
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 3.96px;
}

.h2 {
  font-family: "Overpass", sans-serif;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 3.96px;
  margin: 0;
}

.h3 {
  font-family: "Titillium Web", sans-serif;
  font-size: 22px;
  font-style: italic;
  letter-spacing: 9px;
  font-weight: 500;
}

.bodyText {
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
}

.instructionText {
  font-family: "Titillium Web", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  opacity: 0.8;
}

.link {
  font-family: "Overpass", sans-serif;
  letter-spacing: 3.2px;
  font-weight: 200;
  font-size: 10px;
  text-decoration: underline;
}

.topBar {
  position: absolute;
  left: 0;
  top: 0;
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Overpass", sans-serif;
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 3.96px;
  z-index: 1;
  background-color: #e45c33;
  color: #f9f4e3;
}

.card {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.7rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: "Overpass", sans-serif;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 3.67px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  /* center */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

input {
  -webkit-appearance: none;
  background-color: #f9f4e3;
  border: none;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  margin-right: 0.3rem;
  margin-top: 0.8rem;
  font-family: "Titillium Web", sans-serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 2.96px;
}

hr.rounded {
  margin: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  border: 1px solid #a74525;
  border-radius: 5px;
}

/* The Close Button */

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.player .overlay {
  border-radius: 100%;
  align-items: center;
  position: absolute;
  width: 107%;
  height: 95%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
  max-width: 76px;
}

.current-user {
  border: 3px solid #f9f4e3 !important;
  border-radius: 100%;
  width: 100%;
}

.other-user {
  border: 2px solid #152c51;
  border-radius: 100%;
  width: 100%;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
