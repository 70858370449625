#createRoom p {
  color: white;
  display: inline;
}

#create-new-party {
  background-color: #e45c33;
  color: #f9f4e3;
  display: inline;
  margin-top: 2rem;
}
