.player-avatar {
  width: 17%;
  margin-right: 0.5rem;
}

.player .avatarDiv {
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 70px;
}

.player .avatarImg {
  width: 100%;
}

.barNode {
  transform: translateX(-50%);
}

.host {
  order: 0;
}

.guest {
  order: 1;
}

.player .ready-status,
.player .return-status,
.player .offline-status {
  z-index: 2;
  position: absolute;
  left: 10%;
  right: 0;
  top: 15%;
  bottom: 0;
  margin: 0;
  height: 70%;
}

.player #ready-status-hidden {
  display: none;
}

.player #waiting-status-hidden {
  display: none;
}

#username-text {
  font-size: 12px;
  width: 110%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.hostName {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #a74525;
  color: #a74525;
}

.kickBtn {
  color: #152c51;
  background-color: #f9f4e3;
  position: absolute;
  top: -8%;
  right: -10%;
  width: 20px;
  height: 20px;
  font-size: 15px;
  border-radius: 50%;
  border: 2px solid #152c51;
  transition: all 0.5s;
  margin: 0;
  padding: 0;
  z-index: 5;
}

.kickBtn:hover,
.kickBtn:active {
  color: #f9f4e3;
  background-color: #152c51;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

#closeIcon {
  position: absolute;
  top: 55%;
  left: 60%;
  text-align: center;
  transform: translate(-50%, -50%);
}

/* .player .overlay {
    border-radius: 100%;
    align-items: center;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 0;
    transition: opacity 0.5s;
} */
