#summary {
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#backToPartyRoom {
  position: absolute;
  left: 0;
  top: 0;
  height: 7%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "Overpass", sans-serif;
  font-weight: 800;
  font-size: 10px;
  letter-spacing: 3.96px;
  z-index: 1;
  background-color: #e45c33;
  color: #f9f4e3;
}

#backToPartyIcon {
  transform: rotate(180deg);
  padding: 1rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
  width: 8px;
  /* max-width: 50px; */
  cursor: pointer;
}

#backToPartyInstruction {
  position: absolute;
  left: 3rem;
  top: 50%;
  transform: translateY(-50%);
}

#summaryBanner {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  height: 80px;
  /* height: 13%; */
  justify-content: center;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
  padding: 0;
  background-color: #f9f4e3;
  color: #152c51;
}

#partySummaryTitle {
  font-size: 32px;
  font-weight: 900;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#gameLogos {
  position: absolute;
  top: 16%;
  height: 40px;
  margin-top: 1rem;
}

.gameLogo {
  height: 100%;
}

#player-scores {
  position: absolute;
  top: calc(16% + 70px);
  left: 0;
  width: 100%;
  height: calc(100vh - 16% - 70px);
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: column;
}

.table {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  position: absolute;
  left: 130px;
  width: calc(min(100vw, 720px) - 130px);
  margin-right: 0;
  align-items: center;
}
