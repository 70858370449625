@import url(https://fonts.googleapis.com/css?family=Overpass:200,400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;1,600&display=swap);
html,
body,
#root {
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #f9f4e3;
  /* font-size: 12px; */
}

.screen-wrap {
  width: 100%;
  height: 100%;
  background-color: #f4b640;
}

#home-page {
  background-color: #f9f4e3;
}

#waiting-room,
#game-lobby-wrapper {
  background-color: #f4b640;
}

.page {
  position: relative;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s;
  padding: 0;
}

@-webkit-keyframes slide-in {
  0% {
    top: 0;
    left: 100vw;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@keyframes slide-in {
  0% {
    top: 0;
    left: 100vw;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 720px) {
  @-webkit-keyframes slide-in {
    0% {
      top: 0;
      left: 100vw;
    }
    100% {
      top: 0;
      left: 0;
    }
  }
  @keyframes slide-in {
    0% {
      top: 0;
      left: 100vw;
    }
    100% {
      top: 0;
      left: 0;
    }
  }
}

.fade-enter {
  -webkit-animation: fade-out 3s forwards;
          animation: fade-out 3s forwards;
  position: absolute;
  z-index: 0;
}

.slide-enter {
  position: absolute;
  transition: 1s;
  -webkit-animation: slide-in 2s forwards;
          animation: slide-in 2s forwards;
  z-index: 10;
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.banner {
  /* position: absolute;
    left: 0;
    top: 0; */
  /* padding: 0.5rem; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  font-family: "Overpass", sans-serif;
  font-weight: 900;
  font-size: 120%;
  letter-spacing: 2.92px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.icon {
  width: 10%;
  margin-right: 1.5rem;
  /* flex-shrink: 0; */
}

#back-icon {
  width: 10px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  padding: 1rem;
  margin-left: 0.5rem;
  max-width: 50px;
  cursor: pointer;
}

nav {
  background-color: #152c51;
  /* position: sticky; */
  position: fixed;
  margin-top: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  text-align: center;
}

nav a {
  font-family: "Open Sans", "Overpass", sans-serif;
  font-size: 14px;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 500;
  color: #f9f4e3;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.navLink-active {
  color: #e45c33;
}

.h1 {
  font-family: "Overpass", sans-serif;
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 3.96px;
}

.h2 {
  font-family: "Overpass", sans-serif;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 3.96px;
  margin: 0;
}

.h3 {
  font-family: "Titillium Web", sans-serif;
  font-size: 22px;
  font-style: italic;
  letter-spacing: 9px;
  font-weight: 500;
}

.bodyText {
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
}

.instructionText {
  font-family: "Titillium Web", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  opacity: 0.8;
}

.link {
  font-family: "Overpass", sans-serif;
  letter-spacing: 3.2px;
  font-weight: 200;
  font-size: 10px;
  text-decoration: underline;
}

.topBar {
  position: absolute;
  left: 0;
  top: 0;
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Overpass", sans-serif;
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 3.96px;
  z-index: 1;
  background-color: #e45c33;
  color: #f9f4e3;
}

.card {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.7rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: "Overpass", sans-serif;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 3.67px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  /* center */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

input {
  -webkit-appearance: none;
  background-color: #f9f4e3;
  border: none;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  margin-right: 0.3rem;
  margin-top: 0.8rem;
  font-family: "Titillium Web", sans-serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 2.96px;
}

hr.rounded {
  margin: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  border: 1px solid #a74525;
  border-radius: 5px;
}

/* The Close Button */

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.player .overlay {
  border-radius: 100%;
  align-items: center;
  position: absolute;
  width: 107%;
  height: 95%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
  max-width: 76px;
}

.current-user {
  border: 3px solid #f9f4e3 !important;
  border-radius: 100%;
  width: 100%;
}

.other-user {
  border: 2px solid #152c51;
  border-radius: 100%;
  width: 100%;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  user-select: initial;
}

#loading_screen {
  width: 100vw;
  height: 100vh;
  background-color: #f4b640;
  z-index: 100;
  transition: all 0.5s;
}

#loading_gif {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40%;
  max-width: 200px;
}

#loadingText {
  position: absolute;
  top: 63%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  color: #152c51;
  text-align: center;
  font-size: 18px;
  line-height: 2rem;
}

#createRoom p {
  color: white;
  display: inline;
}

#create-new-party {
  background-color: #e45c33;
  color: #f9f4e3;
  display: inline;
  margin-top: 2rem;
}

#signup {
  box-shadow: none;
  margin-top: 0.2rem;
}

#signup-instruction {
  color: #e45c33;
}

#email-subscribe {
  width: 80%;
}

#email-submit {
  background-color: #ff7f54;
  color: #f9f4e3;
  display: inline;
}

#subscribe-msg {
  color: #a74525;
  margin-top: 0rem;
}

#codeInstructions {
  /*color: #E45C33;*/
  color: white;
  font-size: 14px;
  /*font-weight: bolder;*/
}

#codeMsg {
  color: #a74525;
}

#roomCode input[type="number"] {
  height: 50px;
  width: 30px;
  text-align: center;
  color: #a74525;
  margin-right: 0.5rem;
}

#roomCode input[type="number"]:focus {
  background-color: #fff6de;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* #codeMsg {
    color: #a74525;
    display: none;
    font-weight: 900;
    text-align: left;
    margin-left: 0.3rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
} */

/* Modal Content/Box */

.modal {
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10000;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw;
  /* Full width */
  height: 100vh;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.modal-content {
  background-color: #f4b640;
  position: absolute;
  justify-content: center;
  left: 0;
  right: 0;
  /* top: 0;
    bottom: 0; */
  top: 20vh;
  margin: 0 auto;
  /* margin-top: 100px;
    margin-bottom: auto; */
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  /* Could be more or less, depending on screen size */
}

#close-button {
  color: #f9f4e3;
  cursor: pointer;
}

#tutorial-intrs {
  font-size: 10px;
  color: #f9f4e3;
}

#tutorial-title {
  text-align: center;
  color: #152c51;
  margin-top: 1rem;
}

.modalBtn {
  background-color: #e45c33;
  color: #f9f4e3;
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
}

#guest-login {
  margin: 2rem;
}

#leave-body-text {
  color: #a74525;
}

#guest-login {
  margin: 2rem;
}

#anon-login-submit {
  background-color: #e45c33;
  color: #f9f4e3;
  font-size: 18px;
}

/* The Close Button */

.close {
  color: #f9f4e3;
  position: absolute;
  right: 10px;
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.close:hover,
.close:focus {
  color: #e45c33;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.modal input {
  min-width: 80%;
  margin: auto;
  font-size: 18px;
}

.modal .info-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
  /* margin-left: 1rem; */
}

#errormsg {
  font-size: 11px;
  color: #a74525;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  letter-spacing: 1.96px;
  margin-bottom: 2rem;
}

#test-sign-in {
  float: right;
  color: #ffffff;
  background-color: #e45c33;
  margin-right: 0.5rem;
}

/* images */

#logo {
  width: 40%;
  max-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1.5rem;
  padding-top: 1.2rem;
}

#partyIcon {
  float: right;
  margin-left: 1rem;
  margin-top: 1rem;
  width: 25%;
}

#partyMode {
  background-color: #f4b640;
}

#partyMode .h2,
.bodyText {
  color: #a74525;
}

.js-banner {
  background-color: #e45c33;
  color: #f9f4e3;
}

#banner-logo {
  max-height: 100px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

#banner-p {
  font-size: 90%;
  width: 60%;
}

.player-avatar {
  width: 17%;
  margin-right: 0.5rem;
}

.player .avatarDiv {
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 70px;
}

.player .avatarImg {
  width: 100%;
}

.barNode {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.host {
  order: 0;
}

.guest {
  order: 1;
}

.player .ready-status,
.player .return-status,
.player .offline-status {
  z-index: 2;
  position: absolute;
  left: 10%;
  right: 0;
  top: 15%;
  bottom: 0;
  margin: 0;
  height: 70%;
}

.player #ready-status-hidden {
  display: none;
}

.player #waiting-status-hidden {
  display: none;
}

#username-text {
  font-size: 12px;
  width: 110%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.hostName {
  text-decoration: underline;
  text-underline-position: under;
  -webkit-text-decoration-color: #a74525;
          text-decoration-color: #a74525;
  color: #a74525;
}

.kickBtn {
  color: #152c51;
  background-color: #f9f4e3;
  position: absolute;
  top: -8%;
  right: -10%;
  width: 20px;
  height: 20px;
  font-size: 15px;
  border-radius: 50%;
  border: 2px solid #152c51;
  transition: all 0.5s;
  margin: 0;
  padding: 0;
  z-index: 5;
}

.kickBtn:hover,
.kickBtn:active {
  color: #f9f4e3;
  background-color: #152c51;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

#closeIcon {
  position: absolute;
  top: 55%;
  left: 60%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* .player .overlay {
    border-radius: 100%;
    align-items: center;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 0;
    transition: opacity 0.5s;
} */

#waiting-room {
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#waiting-room #bgl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12%;
}

#party-room {
  font-size: 24px;
}

#room-code {
  letter-spacing: 7px;
  margin: 0.5rem 0 0;
  font-size: 48px;
}

#ask-friends {
  padding: 0 3rem;
  font-size: 16px;
}

#players-waiting-area {
  position: absolute;
  left: 0;
  top: 50%;
  width: 80%;
  height: 35%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  margin-right: 10%;
}

#waiting-room .player {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.7rem;
  /* name */
  color: #f9f4e3;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
  width: calc((100% - 5.6rem) / 4);
  height: auto;
  max-width: 60px;
}

#avatarDiv {
  width: 100%;
  position: relative;
  margin-bottom: 0.3rem;
}

.player img {
  max-width: 70px;
  margin: 0;
}

#bgl {
  right: 0;
  margin: 0;
}

#roomInfo {
  text-align: center;
  color: #a74525;
  position: absolute;
  top: 22%;
  left: 50%;
  width: 80%;
  height: 25%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

#interaction {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 17%;
  max-height: 100px;
  background-color: #e45c33;
  -webkit-user-select: none;
          user-select: none;
  margin: 0;
}

#startPartyButton,
#joinPartyButton {
  display: block;
  font-size: 16px;
  background-color: #e45c33;
  color: #f9f4e3;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#startPartyButton {
  font-family: "Overpass", sans-serif;
  font-weight: 900;
  letter-spacing: 3.67px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

#startPartyText {
  text-align: center;
  margin-bottom: 0.5rem;
}

#startWithText {
  margin-top: 0;
  text-align: center;
  color: #f3d091;
}

#waitingText {
  display: block;
  font-size: 18px;
  text-align: center;
  color: #f9f4e3;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  margin: 0;
  font-size: 14px;
}

#removalInstruction {
  color: #a74525;
  text-align: center;
}

#returnHomeButton {
  background-color: #e45c33;
  color: #f9f4e3;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.react-multi-carousel-list {
  width: 100% !important;
  height: 100% !important;
}

.react-multi-carousel-track {
  height: 100% !important;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.react-multi-carousel-item {
  width: 100vw;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.react-multi-carousel-item > img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  margin: auto;
}

.react-multi-carousel-dot-list {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.react-multi-carousel-dot {
  margin-left: 1em;
}

.react-multi-carousel-dot button {
  width: 2em !important;
  height: 2em !important;
  border-color: rgba(21, 44, 81, 0.2) !important;
  border-width: 1px;
  background-color: rgba(21, 44, 81, 0.2) !important;
}

.react-multi-carousel-dot--active button {
  background-color: rgba(21, 44, 81, 1) !important;
}

#two-story-banner {
  position: absolute;
  top: 7%;
  left: 0;
  width: 100%;
  height: 9%;
  justify-content: center;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
  padding: 0;
  background-color: #f9f4e3;
  color: #152c51;
  width: 100%;
}

#two-story-banner-text {
  font-size: 24px;
  font-weight: 900;
  margin: 0.2rem;
}

#circularG {
  position: relative;
  width: 58px;
  height: 58px;
  margin: auto;
}

.circularG {
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: 14px;
  height: 14px;
  border-radius: 9px;
  -o-border-radius: 9px;
  -ms-border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  animation-name: bounce_circularG;
  -o-animation-name: bounce_circularG;
  -ms-animation-name: bounce_circularG;
  -webkit-animation-name: bounce_circularG;
  -moz-animation-name: bounce_circularG;
  animation-duration: 1.1s;
  -o-animation-duration: 1.1s;
  -ms-animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  -moz-animation-duration: 1.1s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}

#circularG_1 {
  left: 0;
  top: 23px;
  animation-delay: 0.41s;
  -o-animation-delay: 0.41s;
  -ms-animation-delay: 0.41s;
  -webkit-animation-delay: 0.41s;
  -moz-animation-delay: 0.41s;
}

#circularG_2 {
  left: 6px;
  top: 6px;
  animation-delay: 0.55s;
  -o-animation-delay: 0.55s;
  -ms-animation-delay: 0.55s;
  -webkit-animation-delay: 0.55s;
  -moz-animation-delay: 0.55s;
}

#circularG_3 {
  top: 0;
  left: 23px;
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
}

#circularG_4 {
  right: 6px;
  top: 6px;
  animation-delay: 0.83s;
  -o-animation-delay: 0.83s;
  -ms-animation-delay: 0.83s;
  -webkit-animation-delay: 0.83s;
  -moz-animation-delay: 0.83s;
}

#circularG_5 {
  right: 0;
  top: 23px;
  animation-delay: 0.97s;
  -o-animation-delay: 0.97s;
  -ms-animation-delay: 0.97s;
  -webkit-animation-delay: 0.97s;
  -moz-animation-delay: 0.97s;
}

#circularG_6 {
  right: 6px;
  bottom: 6px;
  animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
}

#circularG_7 {
  left: 23px;
  bottom: 0;
  animation-delay: 1.24s;
  -o-animation-delay: 1.24s;
  -ms-animation-delay: 1.24s;
  -webkit-animation-delay: 1.24s;
  -moz-animation-delay: 1.24s;
}

#circularG_8 {
  left: 6px;
  bottom: 6px;
  animation-delay: 1.38s;
  -o-animation-delay: 1.38s;
  -ms-animation-delay: 1.38s;
  -webkit-animation-delay: 1.38s;
  -moz-animation-delay: 1.38s;
}

@keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
}

@-webkit-keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.3);
  }
}

#game-lobby {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#roundNumInfo {
  position: absolute;
  right: 2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
}

.width-100 {
  width: 100%;
}

.min-height-100 {
  min-height: 100%;
}

#how-to-play {
  letter-spacing: "4px";
  font-size: "10px";
  color: #f9f4e3;
}

#gameplay {
  position: absolute;
  top: 16%;
  left: 0;
  height: 64%;
}

#getting-ready {
  position: absolute;
  top: 80%;
  left: 0;
  height: 20%;
  background-color: #152c51;
}

#gamePreview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#check-icon {
  height: 30%;
  width: auto;
  margin-top: 0.7rem;
  margin-bottom: 0.3rem;
  margin-right: 0;
}

#imReady {
  letter-spacing: 4px;
  font-size: 10px;
  color: #f9f4e3;
}

#start-load {
  position: absolute;
  height: 100%;
  width: 25%;
  right: 0;
  background-color: #1c7c54;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

#start {
  position: absolute;
  height: 100%;
  width: 25%;
  right: 0;
  background-color: #1c7c54;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  margin: 0;
}

#players-game-lobby {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: absolute;
  height: 100%;
  width: 69%;
  left: 0;
  top: 0.5rem;
  padding-left: 3%;
  padding-right: 3%;
}

#game-lobby .player {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: calc(25% - 20px);
}

@media screen and (min-width: 720px) {
  #players-game-lobby {
    flex-wrap: nowrap;
  }
}

.filling-empty-space {
  width: 80px;
  /*the maximum width of the players in this example*/
  height: 0;
  /*Important! for the divs to collapse should they fall in a new row*/
}

.tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  bottom: 100%;
  left: 50%;
  margin-left: -40px;
  /* Use half of the width (120/2 = 60), to center the tooltip */
  background-color: #e45c33;
  /* name */
  color: #f9f4e3;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  font-size: 9px;
  letter-spacing: 2px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e45c33 transparent transparent transparent;
  z-index: 3;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

body {
  margin: 0;
  /* Reset default margin */
}

iframe {
  display: block;
  /* iframes are inline by default */
  background: #000;
  border: none;
  /* Reset default border */
  height: 100vh;
  /* Viewport-relative units */
  width: 100vw;
}

#proceed-button {
  position: absolute;
  margin: 0;
  bottom: 1.25rem;
  width: 100%;
  /*height: 14%;*/
  background-color: #42a16b;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

#proceed-text {
  width: 50%;
  letter-spacing: 6px;
  font-size: 10px;
  color: #f9f4e3;
  text-align: center;
  word-wrap: break-word;
  line-height: 1.5rem;
  font-size: 10px;
  background-color: #42a16b;
}

#leaderboard-icon {
  height: 25px;
  width: auto;
  margin-left: 1rem;
}

.firstRank::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAAA9CAYAAAAgRw6nAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR/SURBVHgB7Zs5bxNBGIa/2dghSCBWAgJUGImCI0iBDgSSoSJUVhQaKDA/IIISqqRJTUNBF34CNIgKKCjoSBc66KMo9wEJWeYdezYT48R7zMzORPtI0W7WOexnP8/xzpjIM8JLjdqpoUadPKVCnlHtowkiVuOnX8hD+sgjTl9pDFPA3vDT2vHTl5bW5n58I88IyCcC9kqe7jA2EQ43QvIMb4Tz6m7yQ/1suE33r6/jUljZpufkGf5UeB+bwOHpvRX+tUzHBnaIEa9y3omSR3ghXFR3RDVZ3WfDvzR2c1U8Vq2wafIIPyq8Xd0vRxfjSw9vrRFuAKfu0zDReeGyulHZwxd+x9fRpMgbwBib9qUDdVq4aJ/jtnv5v8dxA8RN4DfElw7UaeH9leCJrG602914ObogjrwDfeZDB+rsxAfygoDe4Xzq8TxvQqKuPyevz/w6MtDHgnB9bvY9OYyzFd6awhMdVN0SdKBo04lFTdc7UCeFi6aBsSZGId3a7k4ge/zBkjjH2JwcxknhsrpHrm/0rG6JMoqpn7nacLYDdU64Wt1jt1ZT/e7Tuyvi6HLOEmCc61LvLmeOmMKLdjkFqHCZs1S3AqeaFjgeHGpMBlFAtWqVfXZB+slrjQbtDahSg7a83YE+F3GuA8AtHEdRcL7VpERuSA+oFb+iurMC2TJnUePcopCy4Rjf77bhBUvvDKjygBsmc5bB1rumEDplg1i4GA0UKb09hZfDu7zInCXi75oiOlBVdvvmC2LhTT7eFZVVgHRZ3ej0bl/eJB0UmbOoske406bSRO4ZFiKXsC09rPHqi+PXBdIJ/l57ocJaztIp+0XHawq6PUmb0vuPBc96BVRZURYqQhsLFb1kg64TH1vS8Xcjnn/gPMkUPgu2FiqSyAb7zjRtSBdTeEPVLUGTIoeZjMwME5PKBgdO7U1Kl1N4nJuqbomSswzrzlnSyAY9sxRT0mVAhfzDVHWrmMhZ0soGicIr3dLVgOr+jTWyASp87Kb4X1pyliyyQeK0UKf0uLrv2aluidzPgpwlTweaVTZIFc/qkC5eqKzunFP4tIgO9G7cgWaq8jyyQeo8PK90bGnAMU9AlQdk7BfPbeG03t4+l5i8skGmBYis0nUGVHkYH2nnNUHynEWHbPEvKSOZpCv7A4tEyWwSbQjVJRvkWmJLI10NqIqsbsn4g8VEOYtO2SD3mmZi6YYCqqwkyVl0ywZaFpF7SR8cGjU+hc/CQTmLCdlA26r9ftJtBFRZae1naT0nOXoCpmQDrdskukmvVs3Frzq4fXkjXqjgVT5pUjbQvi+lUzpFkRgFuFbdKnKYiA7UpGxgZCOQKh3f2wqosoKJkMxZTMoGxj6nKUcjMz/7rQVUecA78OP3o3SHj89NyQZGPxgL6V9nB5yubgk60KlH87w9/0MmMb63UNcqvA1MywZ+fTD2EFAKt0wp3DKlcMuUwi1TCrdMKdwypXDLlMItUwq3TCncMqVwy5TCLVMKt0ych7/+cILefjpOJfpZ3dyt64p6UX2gpORQ8A8aTTdwl5tH+gAAAABJRU5ErkJggg==);
  background-size: 50px 40px;
}

.secondRank::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAAA9CAYAAAAgRw6nAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARySURBVHgB7ZvNTlNBGIa/c6DVRhIrIjVBtEEQVFpJUIgmkm4gYUHSQOIGEtm4hjsod4AXYOIFeBFyCS7dmrgxLtxJFONx3umZw1CKPT8zc2bIeRLSaSD8PP06P+98EDlGda5dH5tvt8hRhskxSkPUIfLqbHhEDjJEDoHK9jzvLRvWr43Nffn5/fMncgyfHILJfh89GfI61YV2lRzDGeG3HrV3KaD66PURmr5XI4yH/9A+OYY7Fc4qGg/rKw3a2XhOlStl8sjbwyJKDuGEcFHdqOzl5n1ClbeW5vCpamlYmmYcwI0KD6sblS2AcIjH0KVtovXCx+c3O6ju5eaUEMypXC3T5uoiH59ZTC3HauGYnwMv2MV4faV57vPN2cloAWVVfkAOYLXwUsnf61fdMpurT/kjX0Ad2CZaK5zvPoKAb/v6VbfgTu0GtZ6FC+iJf0iWY63w7hGeyX7ZvLC6BXhBsE0kNv3YvoBaKZxXt+ftQvTyk6mBX48FFPtzwKaWDlmMlcJFdUtbv4G0lh52F1A25Pt2S7FOOJO1IKo7PNzEBtMPx/cObV1A/XG2nbLqeMxk4UFMEUmYYRXeeDCJYdW2nAWO8c7zA7btKpW8jzZID6eCFp+72RE+DVtriyJn6dhSSPg94Djwqd6dUmyRLgVUaZGnIhtyFiEbjvH8dA7PWboIqCbYvjptdQtsyVl6ZYNI+ERtNF/pYXWLk2MWsE3cDoMu5Cx5LKCybHmnFQlHELTEjtB5SBfVjSP8THdrlxl8n7wuKmTZ+JvkKfLMtnBn44Vx6fxnRHN3k1Qi4lyTFxW9sreZUxn//C9pVnp52H89KKBKC75fuDevloZ87SfQQbJB34OPKek8fqXgAGPV1S3AAmoiZ4kjG1x40jQhXRzhdVS3wETOElc2+O/RXqd0OaDSVd0COWepPW4rXUCTyAYDsxRd0k1Ut4zIWf566vpZksoGscIr1dJNVrdght/486gXFxWZp5Y0skHstFCldHHkznKETwMOVeECus9TyZSklQ0SxbMqpN9stNuUMaBKCxbQKPINU8mkZJHNfywlJKt0n9LHryrAFCZylvHuix+brLJBqguItNLl/kDT1S0jcpaA4l9UqJANUt/4pJIeHuG3pQ6qPEias6iSDTJdsSWRLqq7MTupLKDKQtyGUJWyQeY7zTjSq3X2tg2reytsT8sbuSGU5Tl9F1DVsoGSS+RB0ssjgzuo8kDkLCzPaffmLDpkA2W39hdJH9QfmCe8IXQtbAil022iLtlAaZtEP+n8CG9hdQuwWwpzlgXkLDplA+V9Kb3ScYTH29a26paRcxadsoGWRiBZOp4n6aDKA+yaREOoTtlAW+eVkB63PzBvREOoTtlA6z/GQvrXbz+srm4BFtA3r1Zo+u5t0on23kL0b7uCbtnA2v7wy0oh3DCFcMMUwg1TCDdMIdwwhXDDFMINUwg3TCHcMIVwwxTCDVMIN0wh3DBRHv7uwxFVKmUqUM/x8e9oHAk//nXCPwoKLhX/AMn2cUri+PXoAAAAAElFTkSuQmCC);
  background-size: 50px 40px;
}

.thirdRank::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAAA8CAYAAADrG90CAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ0SURBVHgB7ZzPThNBHMd/s+0SNJgsCBjBEAIoxqLBxISjvfbW9CqJfQLhDcobcPABfBTOHnwHT545GaC16/ym+1uHtWv3z8zsjJlPQljapt1++tv5850pAI6xftTv4g84ShscgzH2BWK45odvwUECcIiNV/0hl73LD4+fdPrn4CBOCYcWG9HhlLFRdNyPwDGcEU7VfbA6ge7OHd4UheNgBI7hToUn1X3auYHe3h08aMe8QY/PXetAnRC+eTQYYXWfPB3D2nIsZPf2bsV9DJhTVW698Ohlfzdm8RCPe/u36e3dnTFsP5qKQ9HcOIL1wsMwOJOrW2bw4mZ20HKnA7VaOFY3xLEY/snVTTxf/QUH/Ac/kPYEnBgmWi08bIFon7G9zlY3gZ0otum8LT8TH5DlWCtcyGNsuLY8hZOtce7j8P50mNjms1DLsVY4VTfKzqtuAjtQFI+Htg8TrRTORx3HVN045l4ENimDw2SYyOyucjsrPGCX+KuIbOLNxiTtQHmVX4ClWCc8GVN3F7Xd8xgczoaJogO1dJgYbPJqsKp3T6bwZaqbeLYylXOWS7AIbCbXecIZxPwSDEN2ZYN0CqiqVDch5SxDWzpQlB232BW/9KJZk2KL9KS6P3RuoCq25Swkm8Ugmrg/bXjD0qm6cQqPM8g64DDxYPYc3SYXKrKykVQ49vJNSRevR233nCl8FajKm1qokGVvr0zT21Ph73lnI06yAelL7eBjXkBVFbxK8Pk4kemcRZaNTpOOXHBvWIgdjmnpIn6F+EK8vqLqJnr7d5SzjEwVUFZ2drT11zjctPR0Cq+wugkpZwETOcsi2cjciY8p6XJApbq6CVM5SxHZSO5M04T0MgFVVbBJoWEm5iw6OtCispF/Tu11Sr9X3RVmlWXQuVBRRjayMEvRJZ3aVN2yidOOlLMoeg9lZSOFwivV0h+/7vehYkBVldmVJPqJKGzV389SRTZSOC1UKT2A8vGrCrADVZGzVJWNlIpnVUhXEVBVRUXOUkc2UjoPry1dQUBVhzo5S13ZSKUFiKrS5f2BdQOqOlTJWVTIRiqv+JSVHu3yNybtD2wSOWcpsiFUlWyk1hJbGelLK/k7qJoAF51pQ6hYtM5BpWyk9ppmEel5+wObBGWnKV6yaJ1FtWzxUqCARdLFFN6i6ibwvCln2ZzNDVJ0yEaUrdrnSacpvHiMJdUtQ6MlHuJeUgeqSzaidJvEPOlF9gc2STZn0SkbUb4vJSu9yP7AppE3hOqUjWjZCCRLp79trG5C3hCqUzai7XuadMJff4RWVzeBM1A6V50Zj9YvxuKJn2xNwAWwSfn07qf2K1H73sJk2OUEJpo9t74Y+x/ghRvGCzeMF24YL9wwXrhhvHDDeOGG8cIN44Ubxgs3jBduGC/cMF64YdI8/PO3h+DRT1v8l8sAvoNHP1O4/g3Q2SdOUd8TMgAAAABJRU5ErkJggg==);
  background-size: 50px 40px;
}

#totalNotOnScreen {
  left: 100vw;
}

.barNode .player {
  height: 100%;
}

.player-wrapper {
  margin-bottom: 0.7rem;
  width: 100%;
  height: calc((86% - 5.6rem - 0.7rem) / 8);
  max-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 1s;
  position: relative;
}

.player-wrapper::before {
  content: "";
  width: 50px;
  height: 40px;
  margin-left: 8%;
  position: absolute;
}

.player-bar.self {
  background-color: #152c51;
}

.player-bar.otherPlayer {
  background-color: #1c7c54;
}

.player-bar.droppedPlayer {
  background-color: #8c042b;
}

.bar-overlay {
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.cur-score {
  font-family: "Overpass", sans-serif;
  font-size: 130%;
  font-weight: 600;
  letter-spacing: 3.96px;
  color: #f9f4e3;
  position: absolute;
  left: 25%;
  margin: 0;
  padding: 0;
}

.parallel {
  position: absolute;
  right: 0;
  width: 40%;
  height: 100%;
  -webkit-transform: skew(320deg);
          transform: skew(320deg);
  background: #42a16b;
}

.rect {
  position: absolute;
  right: 0;
  margin-right: -20%;
  margin-left: auto;
  width: 40%;
  height: 100%;
  background: #42a16b;
}

.added-score {
  font-family: "Overpass", sans-serif;
  font-size: 130%;
  font-weight: 400;
  letter-spacing: 50%;
  color: #f9f4e3;
  position: absolute;
  right: 10%;
  margin: 0;
  padding: 0;
}

.round-score {
  font-family: "Overpass", sans-serif;
  font-size: 130%;
  font-weight: 600;
  letter-spacing: 3.96px;
  color: #f9f4e3;
  position: absolute;
  right: 45%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  margin: 0;
  padding: 0;
}

#post-game {
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#players-status {
  height: 7%;
  /* width: 100%; */
  background-color: #e45c33;
  color: #f9f4e3;
}

#waiting-status {
  margin-top: 0rem;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 2rem;
  font-size: 12px;
  font-weight: 100;
  font-style: italic;
}

.firstRank::before {
  /* background: url('../img/icons/gold.png'); */
  background-size: 50px 40px;
}

.secondRank::before {
  /* background: url('../img/icons/silver.png'); */
  background-size: 50px 40px;
}

.thirdRank::before {
  /* background: url('../img/icons/bronze.png'); */
  background-size: 50px 40px;
}

#round-ranking .player-bar {
  position: relative;
  left: 35%;
  /* right: 0; */
  display: flex;
  flex-flow: row nowrap;
  width: 65%;
  /* height: 10%; */
  height: 100%;
  margin-right: 0;
  /* margin-left: auto; */
  align-items: center;
}

#total-ranking .player-bar {
  position: relative;
  left: 35%;
  /* right: 0; */
  display: flex;
  flex-flow: row nowrap;
  width: 65%;
  /* height: 10%; */
  height: 100%;
  margin-right: 0;
  /* margin-left: auto; */
  background-color: #152c51;
  align-items: center;
}

#post-game .avatar {
  max-height: 70px;
  height: 100%;
  /* same as the height of player bar */
  width: auto;
  margin: 0;
  left: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border: 2px solid #152c51;
  border-radius: 100%;
}

.roundScore {
  font-family: "Overpass", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 3.96px;
  color: #f9f4e3;
  position: absolute;
  right: 40%;
  margin: 0;
  padding: 0;
}

.addedScore {
  font-family: "Overpass", sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 3.96px;
  color: #f9f4e3;
  position: absolute;
  right: 10%;
  margin: 0;
  padding: 0;
}

.rankingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ranking {
  position: absolute;
  top: 16%;
  width: 100%;
  height: 84%;
  margin-top: 1.5rem;
  display: flex;
  flex-flow: column;
}

.ranking .barNode .avatarDiv {
  height: 100%;
  width: auto;
}

.ranking .barNode {
  height: 100%;
}

.ranking .current-user,
.ranking .other-user {
  height: 90%;
  width: auto;
}

.ranking .player .overlay {
  width: 100%;
}

.summary_player-bar {
  position: relative;
  left: 0;
  width: 100%;
  height: calc((100% - 5.6rem - 0.7rem) / 8);
  max-height: 70px;
  margin-bottom: 0.7rem;
  transition: all 1s;
}

.summary_total-score {
  display: inline-flex;
  align-items: center;
  /* justify-content: flex-start; */
  padding-left: 30px;
  color: #f9f4e3;
  background-color: #e45c33;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.summary_avatar {
  position: absolute;
  height: 95%;
  width: auto;
  /* same as the height of player bar */
  /* margin-left: -10%; */
  top: 0;
  left: 100px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border: 2px solid #152c51;
  border-radius: 100%;
  z-index: 1;
}

.summary_round-score {
  color: #f9f4e3;
  border: 1px solid #f9f4e3;
  width: 40px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.summary_table {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  position: absolute;
  left: 130px;
  width: calc(min(100vw, 720px) - 130px);
  margin-right: 0;
  align-items: center;
}

#summary {
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#backToPartyRoom {
  position: absolute;
  left: 0;
  top: 0;
  height: 7%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "Overpass", sans-serif;
  font-weight: 800;
  font-size: 10px;
  letter-spacing: 3.96px;
  z-index: 1;
  background-color: #e45c33;
  color: #f9f4e3;
}

#backToPartyIcon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  padding: 1rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
  width: 8px;
  /* max-width: 50px; */
  cursor: pointer;
}

#backToPartyInstruction {
  position: absolute;
  left: 3rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

#summaryBanner {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  height: 80px;
  /* height: 13%; */
  justify-content: center;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
  padding: 0;
  background-color: #f9f4e3;
  color: #152c51;
}

#partySummaryTitle {
  font-size: 32px;
  font-weight: 900;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#gameLogos {
  position: absolute;
  top: 16%;
  height: 40px;
  margin-top: 1rem;
}

.gameLogo {
  height: 100%;
}

#player-scores {
  position: absolute;
  top: calc(16% + 70px);
  left: 0;
  width: 100%;
  height: calc(100vh - 16% - 70px);
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: column;
}

.table {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  position: absolute;
  left: 130px;
  width: calc(min(100vw, 720px) - 130px);
  margin-right: 0;
  align-items: center;
}

