#waiting-room {
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#waiting-room #bgl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12%;
}

#party-room {
  font-size: 24px;
}

#room-code {
  letter-spacing: 7px;
  margin: 0.5rem 0 0;
  font-size: 48px;
}

#ask-friends {
  padding: 0 3rem;
  font-size: 16px;
}

#players-waiting-area {
  position: absolute;
  left: 0;
  top: 50%;
  width: 80%;
  height: 35%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  margin-right: 10%;
}

#waiting-room .player {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.7rem;
  /* name */
  color: #f9f4e3;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
  width: calc((100% - 5.6rem) / 4);
  height: auto;
  max-width: 60px;
}

#avatarDiv {
  width: 100%;
  position: relative;
  margin-bottom: 0.3rem;
}

.player img {
  max-width: 70px;
  margin: 0;
}

#bgl {
  right: 0;
  margin: 0;
}

#roomInfo {
  text-align: center;
  color: #a74525;
  position: absolute;
  top: 22%;
  left: 50%;
  width: 80%;
  height: 25%;
  transform: translateX(-50%);
}

#interaction {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 17%;
  max-height: 100px;
  background-color: #e45c33;
  user-select: none;
  margin: 0;
}

#startPartyButton,
#joinPartyButton {
  display: block;
  font-size: 16px;
  background-color: #e45c33;
  color: #f9f4e3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#startPartyButton {
  font-family: "Overpass", sans-serif;
  font-weight: 900;
  letter-spacing: 3.67px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

#startPartyText {
  text-align: center;
  margin-bottom: 0.5rem;
}

#startWithText {
  margin-top: 0;
  text-align: center;
  color: #f3d091;
}

#waitingText {
  display: block;
  font-size: 18px;
  text-align: center;
  color: #f9f4e3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 0;
  font-size: 14px;
}
