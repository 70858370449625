#proceed-button {
  position: absolute;
  margin: 0;
  bottom: 1.25rem;
  width: 100%;
  /*height: 14%;*/
  background-color: #42a16b;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  cursor: pointer;
  user-select: none;
}

#proceed-text {
  width: 50%;
  letter-spacing: 6px;
  font-size: 10px;
  color: #f9f4e3;
  text-align: center;
  word-wrap: break-word;
  line-height: 1.5rem;
  font-size: 10px;
  background-color: #42a16b;
}

#leaderboard-icon {
  height: 25px;
  width: auto;
  margin-left: 1rem;
}
