#test-sign-in {
  float: right;
  color: #ffffff;
  background-color: #e45c33;
  margin-right: 0.5rem;
}

/* images */

#logo {
  width: 40%;
  max-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1.5rem;
  padding-top: 1.2rem;
}

#partyIcon {
  float: right;
  margin-left: 1rem;
  margin-top: 1rem;
  width: 25%;
}

#partyMode {
  background-color: #f4b640;
}

#partyMode .h2,
.bodyText {
  color: #a74525;
}
