#two-story-banner {
  position: absolute;
  top: 7%;
  left: 0;
  width: 100%;
  height: 9%;
  justify-content: center;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
  padding: 0;
  background-color: #f9f4e3;
  color: #152c51;
  width: 100%;
}

#two-story-banner-text {
  font-size: 24px;
  font-weight: 900;
  margin: 0.2rem;
}
