.summary_player-bar {
  position: relative;
  left: 0;
  width: 100%;
  height: calc((100% - 5.6rem - 0.7rem) / 8);
  max-height: 70px;
  margin-bottom: 0.7rem;
  transition: all 1s;
}

.summary_total-score {
  display: inline-flex;
  align-items: center;
  /* justify-content: flex-start; */
  padding-left: 30px;
  color: #f9f4e3;
  background-color: #e45c33;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.summary_avatar {
  position: absolute;
  height: 95%;
  width: auto;
  /* same as the height of player bar */
  /* margin-left: -10%; */
  top: 0;
  left: 100px;
  transform: translateX(-50%);
  border: 2px solid #152c51;
  border-radius: 100%;
  z-index: 1;
}

.summary_round-score {
  color: #f9f4e3;
  border: 1px solid #f9f4e3;
  width: 40px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.summary_table {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  position: absolute;
  left: 130px;
  width: calc(min(100vw, 720px) - 130px);
  margin-right: 0;
  align-items: center;
}
