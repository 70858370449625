.js-banner {
  background-color: #e45c33;
  color: #f9f4e3;
}

#banner-logo {
  max-height: 100px;
  transform: rotate(45deg);
}

#banner-p {
  font-size: 90%;
  width: 60%;
}
