#guest-login {
  margin: 2rem;
}

#anon-login-submit {
  background-color: #e45c33;
  color: #f9f4e3;
  font-size: 18px;
}

/* The Close Button */

.close {
  color: #f9f4e3;
  position: absolute;
  right: 10px;
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.close:hover,
.close:focus {
  color: #e45c33;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.modal input {
  min-width: 80%;
  margin: auto;
  font-size: 18px;
}

.modal .info-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
  /* margin-left: 1rem; */
}

#errormsg {
  font-size: 11px;
  color: #a74525;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  letter-spacing: 1.96px;
  margin-bottom: 2rem;
}
