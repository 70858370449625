#removalInstruction {
  color: #a74525;
  text-align: center;
}

#returnHomeButton {
  background-color: #e45c33;
  color: #f9f4e3;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
