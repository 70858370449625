#codeInstructions {
  /*color: #E45C33;*/
  color: white;
  font-size: 14px;
  /*font-weight: bolder;*/
}

#codeMsg {
  color: #a74525;
}

#roomCode input[type="number"] {
  height: 50px;
  width: 30px;
  text-align: center;
  color: #a74525;
  margin-right: 0.5rem;
}

#roomCode input[type="number"]:focus {
  background-color: #fff6de;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* #codeMsg {
    color: #a74525;
    display: none;
    font-weight: 900;
    text-align: left;
    margin-left: 0.3rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
} */
