#game-lobby {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#roundNumInfo {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.width-100 {
  width: 100%;
}

.min-height-100 {
  min-height: 100%;
}

#how-to-play {
  letter-spacing: "4px";
  font-size: "10px";
  color: #f9f4e3;
}

#gameplay {
  position: absolute;
  top: 16%;
  left: 0;
  height: 64%;
}

#getting-ready {
  position: absolute;
  top: 80%;
  left: 0;
  height: 20%;
  background-color: #152c51;
}

#gamePreview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#check-icon {
  height: 30%;
  width: auto;
  margin-top: 0.7rem;
  margin-bottom: 0.3rem;
  margin-right: 0;
}

#imReady {
  letter-spacing: 4px;
  font-size: 10px;
  color: #f9f4e3;
}

#start-load {
  position: absolute;
  height: 100%;
  width: 25%;
  right: 0;
  background-color: #1c7c54;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

#start {
  position: absolute;
  height: 100%;
  width: 25%;
  right: 0;
  background-color: #1c7c54;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  margin: 0;
}

#players-game-lobby {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: absolute;
  height: 100%;
  width: 69%;
  left: 0;
  top: 0.5rem;
  padding-left: 3%;
  padding-right: 3%;
}

#game-lobby .player {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: calc(25% - 20px);
}

@media screen and (min-width: 720px) {
  #players-game-lobby {
    flex-wrap: nowrap;
  }
}

.filling-empty-space {
  width: 80px;
  /*the maximum width of the players in this example*/
  height: 0;
  /*Important! for the divs to collapse should they fall in a new row*/
}

.tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  bottom: 100%;
  left: 50%;
  margin-left: -40px;
  /* Use half of the width (120/2 = 60), to center the tooltip */
  background-color: #e45c33;
  /* name */
  color: #f9f4e3;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  font-size: 9px;
  letter-spacing: 2px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e45c33 transparent transparent transparent;
  z-index: 3;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
