/* Modal Content/Box */

.modal {
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10000;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw;
  /* Full width */
  height: 100vh;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.modal-content {
  background-color: #f4b640;
  position: absolute;
  justify-content: center;
  left: 0;
  right: 0;
  /* top: 0;
    bottom: 0; */
  top: 20vh;
  margin: 0 auto;
  /* margin-top: 100px;
    margin-bottom: auto; */
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  /* Could be more or less, depending on screen size */
}

#close-button {
  color: #f9f4e3;
  cursor: pointer;
}

#tutorial-intrs {
  font-size: 10px;
  color: #f9f4e3;
}

#tutorial-title {
  text-align: center;
  color: #152c51;
  margin-top: 1rem;
}

.modalBtn {
  background-color: #e45c33;
  color: #f9f4e3;
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
}

#guest-login {
  margin: 2rem;
}

#leave-body-text {
  color: #a74525;
}
