#signup {
  box-shadow: none;
  margin-top: 0.2rem;
}

#signup-instruction {
  color: #e45c33;
}

#email-subscribe {
  width: 80%;
}

#email-submit {
  background-color: #ff7f54;
  color: #f9f4e3;
  display: inline;
}

#subscribe-msg {
  color: #a74525;
  margin-top: 0rem;
}
