.react-multi-carousel-list {
  width: 100% !important;
  height: 100% !important;
}

.react-multi-carousel-track {
  height: 100% !important;
  padding-inline-start: 0;
}

.react-multi-carousel-item {
  width: 100vw;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.react-multi-carousel-item > img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  margin: auto;
}

.react-multi-carousel-dot-list {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.react-multi-carousel-dot {
  margin-left: 1em;
}

.react-multi-carousel-dot button {
  width: 2em !important;
  height: 2em !important;
  border-color: rgba(21, 44, 81, 0.2) !important;
  border-width: 1px;
  background-color: rgba(21, 44, 81, 0.2) !important;
}

.react-multi-carousel-dot--active button {
  background-color: rgba(21, 44, 81, 1) !important;
}
