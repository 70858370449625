#loading_screen {
  width: 100vw;
  height: 100vh;
  background-color: #f4b640;
  z-index: 100;
  transition: all 0.5s;
}

#loading_gif {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  max-width: 200px;
}

#loadingText {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: #152c51;
  text-align: center;
  font-size: 18px;
  line-height: 2rem;
}
