#post-game {
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#players-status {
  height: 7%;
  /* width: 100%; */
  background-color: #e45c33;
  color: #f9f4e3;
}

#waiting-status {
  margin-top: 0rem;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 2rem;
  font-size: 12px;
  font-weight: 100;
  font-style: italic;
}

.firstRank::before {
  /* background: url('../img/icons/gold.png'); */
  background-size: 50px 40px;
}

.secondRank::before {
  /* background: url('../img/icons/silver.png'); */
  background-size: 50px 40px;
}

.thirdRank::before {
  /* background: url('../img/icons/bronze.png'); */
  background-size: 50px 40px;
}

#round-ranking .player-bar {
  position: relative;
  left: 35%;
  /* right: 0; */
  display: flex;
  flex-flow: row nowrap;
  width: 65%;
  /* height: 10%; */
  height: 100%;
  margin-right: 0;
  /* margin-left: auto; */
  align-items: center;
}

#total-ranking .player-bar {
  position: relative;
  left: 35%;
  /* right: 0; */
  display: flex;
  flex-flow: row nowrap;
  width: 65%;
  /* height: 10%; */
  height: 100%;
  margin-right: 0;
  /* margin-left: auto; */
  background-color: #152c51;
  align-items: center;
}

#post-game .avatar {
  max-height: 70px;
  height: 100%;
  /* same as the height of player bar */
  width: auto;
  margin: 0;
  left: 0;
  transform: translateX(-50%);
  border: 2px solid #152c51;
  border-radius: 100%;
}

.roundScore {
  font-family: "Overpass", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 3.96px;
  color: #f9f4e3;
  position: absolute;
  right: 40%;
  margin: 0;
  padding: 0;
}

.addedScore {
  font-family: "Overpass", sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 3.96px;
  color: #f9f4e3;
  position: absolute;
  right: 10%;
  margin: 0;
  padding: 0;
}

.rankingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ranking {
  position: absolute;
  top: 16%;
  width: 100%;
  height: 84%;
  margin-top: 1.5rem;
  display: flex;
  flex-flow: column;
}

.ranking .barNode .avatarDiv {
  height: 100%;
  width: auto;
}

.ranking .barNode {
  height: 100%;
}

.ranking .current-user,
.ranking .other-user {
  height: 90%;
  width: auto;
}

.ranking .player .overlay {
  width: 100%;
}
