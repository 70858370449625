.firstRank::before {
  background: url("./gold.png");
  background-size: 50px 40px;
}

.secondRank::before {
  background: url("./silver.png");
  background-size: 50px 40px;
}

.thirdRank::before {
  background: url("./bronze.png");
  background-size: 50px 40px;
}

#totalNotOnScreen {
  left: 100vw;
}

.barNode .player {
  height: 100%;
}

.player-wrapper {
  margin-bottom: 0.7rem;
  width: 100%;
  height: calc((86% - 5.6rem - 0.7rem) / 8);
  max-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 1s;
  position: relative;
}

.player-wrapper::before {
  content: "";
  width: 50px;
  height: 40px;
  margin-left: 8%;
  position: absolute;
}

.player-bar.self {
  background-color: #152c51;
}

.player-bar.otherPlayer {
  background-color: #1c7c54;
}

.player-bar.droppedPlayer {
  background-color: #8c042b;
}

.bar-overlay {
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.cur-score {
  font-family: "Overpass", sans-serif;
  font-size: 130%;
  font-weight: 600;
  letter-spacing: 3.96px;
  color: #f9f4e3;
  position: absolute;
  left: 25%;
  margin: 0;
  padding: 0;
}

.parallel {
  position: absolute;
  right: 0;
  width: 40%;
  height: 100%;
  transform: skew(320deg);
  background: #42a16b;
}

.rect {
  position: absolute;
  right: 0;
  margin-right: -20%;
  margin-left: auto;
  width: 40%;
  height: 100%;
  background: #42a16b;
}

.added-score {
  font-family: "Overpass", sans-serif;
  font-size: 130%;
  font-weight: 400;
  letter-spacing: 50%;
  color: #f9f4e3;
  position: absolute;
  right: 10%;
  margin: 0;
  padding: 0;
}

.round-score {
  font-family: "Overpass", sans-serif;
  font-size: 130%;
  font-weight: 600;
  letter-spacing: 3.96px;
  color: #f9f4e3;
  position: absolute;
  right: 45%;
  transform: translateX(50%);
  margin: 0;
  padding: 0;
}
